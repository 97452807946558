<painel-header></painel-header>
<main>
  <div class="sidebar-controller">
    <p class="sidebar-titulo">Funções</p>
    <button (click)="mudarControle(Controle.CriarProduto)">Criar Produto</button>
    <button (click)="mudarControle(Controle.EditarProduto)">Editar Produto</button>
    <button (click)="mudarControle(Controle.RemoverProduto)">Remover Produto</button>
    <button (click)="mudarControle(Controle.CriarFrete)">Criar Frete</button>

  </div>
  <div class="controle">
    <div *ngIf="selecao === Controle.CriarProduto" class="controle-selecao">
      <h2>Criar Produto</h2>
      <label>Nome Produto</label>
      <input placeholder="nome" #nomeProduto>

      <label>Descrição Produto</label>
      <input placeholder="descricao" #descricaoProduto>

      <label>Preço Produto</label>
      <input placeholder="preco" #precoProduto>

      <label>Imagem Produto</label>
      <input placeholder="imagem" (blur)="adquirirImagem($event)">
      <img id="img-criar" class="img-preview" [src]="srcImgCriar" #previewImgProduto/>

      <button class="neutro" (click)="criarProduto()">Criar Produto</button>

    </div>
    <div *ngIf="selecao === Controle.EditarProduto" class="controle-selecao">
      <ng-template *ngIf="edicaoProduto && edicaoProdutoInicial; then editEditarProduto; else editPesquisarProduto">

      </ng-template>
      <ng-template class="subtab" #editEditarProduto>
        <p class="titulo-funcionalidade">Editar Produto</p>
        <label>Nome Produto</label>
        <input placeholder="nome" #editNomeProduto>

        <label>Descrição Produto</label>
        <input placeholder="descricao" #editDescricaoProduto>

        <label>Preço Produto</label>
        <input placeholder="preco" #editPrecoProduto>

        <label>Imagem Produto</label>
        <input placeholder="imagem" (blur)="adquirirImagemEdit($event)" #imgProduto>
        <img id="img-edit" class="img-preview" [src]="srcImgEdit" #editPreviewImgProduto/>

        <button class="" (click)="editarProduto()">Editar Produto</button>
        <button class="perigo" (click)="deselecionarProduto()">Selecionar Outro Produto</button>
        <p class="titulo-funcionalidade">Selecionar Produto</p>
      </ng-template>
      <ng-template class="subtab" #editPesquisarProduto>
        <p class="titulo-funcionalidade">Pesquisar Produto para editar</p>
        <input placeholder="Pesquise um produto" [value]="pesquisaProdutos" #inputPesquisaEditarProduto>
        <button (click)="pesquisarProdutos($event)">Pesquisar</button>
        <nav-produtos [produtos]="produtosEditar" (aoClicar)="selecionarProdutoEditar($event)" [cliqueCustomizado]="true" tamanho="P"></nav-produtos>
      </ng-template>
    </div>
    <div *ngIf="selecao === Controle.RemoverProduto" class="controle-selecao">
      <p class="titulo-funcionalidade">Pesquisar Produto para Remover</p>
      <input placeholder="Pesquise um produto" [value]="pesquisaProdutos" #inputPesquisaEditarProduto>
      <button (click)="pesquisarProdutos($event)">Pesquisar</button>
      <nav-produtos [produtos]="produtosEditar" (aoClicar)="removerProduto($event)" [cliqueCustomizado]="true" tamanho="P" textoClique="Remover" [cliquePerigoso]="true"></nav-produtos>
    </div>
    <div *ngIf="selecao === Controle.CriarFrete" class="controle-selecao">
      <h2>Criar Frete</h2>
      <label>Nome Frete</label>
      <input placeholder="Nome" #nomeFrete>

      <label>Prazo em Horas</label>
      <input placeholder="Prazo em Horas" #prazoHorasFrete>

      <label>Valor Frete</label>
      <input placeholder="Valor Produto" #valorFrete>

      <button class="neutro" (click)="criarFrete()">Criar Frete</button>

    </div>
  </div>
</main>


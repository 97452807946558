<painel-header class="header"></painel-header>
<main>
    <section id="seccao-principal" *ngIf="produto">
        <div class="card-imagem">
            <img *ngIf="produto.imagem != null" class="imagem-produto" [src]="produto.imagem" [alt]="produto.imagem">
        </div>
        <div id="info-principal" >
            <header id="info-principal-header">
                <p id="titulo-produto">{{produto.nome}}</p>
                <p id="preco-produto">R${{produto.preco}}</p>
            </header>
            <p id="descricao-produto">{{produto.descricao}}</p>

            <footer id="info-principal-footer">
                <button *ngIf="!estaEmCarrinho" (click)="adicionarProdutoCarrinho()">Adicionar ao Carrinho</button>
                <button class="perigo" *ngIf="estaEmCarrinho" (click)="removerProdutoCarrinho()">Remover do Carrinho</button>
                <button class="neutro">Observar</button>
            </footer>
        </div>
    </section>
    Comentarios
</main>

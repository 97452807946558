<section class="explorador-produtos" >
    <div class="card-produto" *ngFor="let produto of produtos">
    <header class="header-produto">
        <p>
        {{produto.nome}}
        </p>
        <p *ngIf="produto.numAvaliacoes > 0">
        {{produto.avaliacao.toFixed(2)}}
        </p>
    </header>
    <img class="imagem-produto" *ngIf="produto.imagem" [src]="produto.imagem">
    <footer class="footer-produto">
        <p class="">
        R$ {{produto.preco.toFixed(2)}}
        </p>


        <ng-template *ngIf="cliqueCustomizado; then botaoClique; else botaoExpandir" >

        </ng-template>
        <ng-template #botaoClique>
          <button [class]="cliquePerigoso ? 'perigo' : ''" (click)="transmitirClique(produto)">
            {{ textoClique }}
          </button>
        </ng-template>
        <ng-template #botaoExpandir>
          <a *ngIf="produto.id" routerLink="/produto" [queryParams]="{id:produto.id}">
            <button [class]="cliquePerigoso ? 'perigo' : ''" >
              Expandir
            </button>
          </a>
        </ng-template>


    </footer>
    </div>
</section>

import { Component, OnInit } from '@angular/core';
import { Produto } from './models/produto';
import { ServicoProduto } from './services/servico-produto.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'loja generica';
}

<header id="painel-cabecalho" #painelHeader>
    <a routerLink="/">Inicio</a>
    <a routerLink="/produtos">Produtos</a>
    <a routerLink="/contato">Contato</a>
    <input  class="barra-pesquisa" placeholder="Pesquisar Produto..." type="text" (input)="atualizarPesquisa($event)" [value]="pesquisa" #inputPesquisa>

    <a routerLink="/produtos" [queryParams]="{s: pesquisa}"><button class="neutro">Pesquisar</button></a>

    <div class="direita-header">
        <a *ngIf="usuario && usuario.admin" routerLink="/admin">Admin</a>
        <a *ngIf="usuario" routerLink="/ordens">Ordens</a>
        <a *ngIf="usuario" (click)="toggleCarrinho()" >Carrinho ({{usuario?.carrinho?.produtos?.length}})</a>
        <a *ngIf="!usuario" routerLink="/entrar">Entrar</a>
        <a *ngIf="usuario" routerLink="/sair">Sair</a>
    </div>
</header>
<div [class]="'div-escurecer ' + (carrinhoAberto ? 'on' : '')"></div>
<div id="popup-carrinho" [class]="'' + (carrinhoAberto ? 'on' : '')">
    <div class="conteudo-popup">
        <header id="header-popup">
            <p id="popup-carrinho-titulo">Seu Carrinho</p>
            <button class="neutro" (click)="toggleCarrinho()">Fechar</button>
        </header>
        <section *ngIf="usuario && usuario.carrinho" class="conteudo-carrinho">
            <div *ngFor="let produtoCarrinho of usuario.carrinho.produtos; index as i;" class="card-carrinho">
                <a  routerLink="/produto" [queryParams]="{id: produtoCarrinho.produto.id}" (click)="fecharCarrinho()">
                    <img [src]="produtoCarrinho.produto.imagem" class="imagem-produto-carrinho">
                </a>
                <div class="texto-produto-carrinho">
                    <p class="titulo-produto-carrinho">
                        {{ produtoCarrinho.produto.nome }}
                    </p>
                    <p class="preco-produto-carrinho">
                        R$ {{ produtoCarrinho.produto.preco }}
                    </p>
                    <div class="container-botoes-carrinho">
                        <button class="botao-produto-carrinho perigo" (click)="removerDoCarrinho(produtoCarrinho)">Remover</button>
                        <!-- <a  routerLink="/produto" [queryParams]="{id: produto.id}" (click)="fecharCarrinho()">
                            <button class="botao-produto-carrinho neutro">Abrir</button>
                        </a> -->
                        <input type="text" placeholder="quantidade" class="input-quantidade" [value]="produtoCarrinho.quantidade" (input)="atualizarQuantidadeProduto(produtoCarrinho, $event)">
                    </div>
                </div>

            </div>
        </section>
        <button *ngIf="usuario && usuario.carrinho && usuario.carrinho.produtos && usuario.carrinho.produtos.length > 0" class="perigo" (click)="limparCarrinho()">Limpar Carrinho</button>
        <section class="resumo-carrinho">
            <p class="titulo-resumo-carrinho">Resumo</p>
            <div class="valor-resumo">
                <p>Total</p>
                <p class="total-resumo">R$ {{total}}</p>
            </div>
        </section>
        <a routerLink="/finalizar" class="botao-finalizar">
            <button class="neutro" >Finalizar Compra</button>
        </a>
    </div>
</div>

<painel-header></painel-header>
  <main>
    <section class="showcase-inicio">
      <div class="showcase-texto">
        <p >
          Eai <span class="cor-complementar">Usuario,</span><br/>
          Bora Comprar?
        </p>
        <p class="texto-inicio-sub">
          Encontre o Produto perfeito para voce!
        </p>
      </div>
      <div id="cards-showcase">
        <div class="card-showcase" *ngIf="preview1">
          <!-- <p class="showcase-titulo">{{ preview1.nome }}</p> -->
          <img class="showcase-img" [src]="preview1.imagem">
        </div>

        <div class="card-showcase" *ngIf="preview2">
          <!-- <p class="showcase-titulo">{{ preview2.nome }}</p> -->
          <img class="showcase-img" [src]="preview2.imagem">
        </div>
      </div>
    </section>
    <nav-produtos [produtos]="produtos"/>
  </main>

